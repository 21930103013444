<script>
import RecordData from "@/components/thirdParty/other/redpacket/globalOverrideConfig/jumpCancel/recordData.vue";
import IPLocationBlackList
    from "@/components/thirdParty/other/redpacket/globalOverrideConfig/jumpCancel/IPLocationBlackList.vue";
export default {
    name: "jumpCancel",
    components:{
        RecordData,
        IPLocationBlackList
    },
    props: {
        json_value:{
            type:String ,
            default:"{}"
        }
    },
    data() {
        return {
            data_value:{
                // 是否开始记录日志
                isStartRecord:false,
                // 是否取消跳转
                isJumpCancel:false,
                // ip归属地黑名单
                IPLocationBlackList:null,
                // isp黑名单 (网络运营提供商)
                ISPBlackList:null,
            },
        }
    },
    methods:{
        // 加载组件内数据
        init(){
            if(this.json_value) {
                this.data_value = JSON.parse(this.json_value);
                // 如果没有ip归属地黑名单，就初始化为空数组
                this.data_value.IPLocationBlackList = this.data_value.IPLocationBlackList || "[]";
                // 如果没有isp黑名单，就初始化为空数组
                this.data_value.ISPBlackList = this.data_value.ISPBlackList || "[]";
            }
        }
    },
    created(){
        const  intervalTimer = setInterval(
            ()=>{
                if(this.data_value){
                    this.init();
                    clearInterval(intervalTimer);
                }
            },
            1000
        )
    },
    watch:{
        //对结果进行解码，上传给父组件
        data_value:{
            deep:true,
            handler(newValue){
                this.$emit("update:json_value",JSON.stringify(newValue));
            }
        }
    }
}
</script>

<template>
<div>
    <el-form label-width="130px">
        <div class="activity_block">
            <el-form-item label="是否开始记录数据">
                <el-switch  v-model="data_value.isStartRecord"></el-switch>
            </el-form-item>
            <el-form-item label="是否开始屏蔽">
                <el-switch  v-model="data_value.isJumpCancel"></el-switch>
            </el-form-item>
            <el-form-item label="屏蔽城市">
                <IPLocationBlackList
                    v-if="data_value.IPLocationBlackList"
                    :input_list.sync="data_value.IPLocationBlackList"
                    label_name="省份或者城市"
                    >
                </IPLocationBlackList>
            </el-form-item>
            <el-form-item label="屏蔽ISP">
                <IPLocationBlackList
                    v-if="data_value.ISPBlackList"
                    :input_list.sync="data_value.ISPBlackList"
                    label_name="ISP"
                >
                </IPLocationBlackList>
            </el-form-item>
        </div>
    </el-form>
    <record-data></record-data>
    <div    class="padding"></div>
</div>
</template>

<style scoped lang="less">
.title{
    font-size: 24px;
    color: #333333;
    margin-top: 30px;
    margin-bottom: 10px;
    .desc{
        font-size: 16px;
        color: #999999;
        margin-left: 10px;
    }
}
.activity_block{
    background-color: #FFFFFF;
    padding: 30px ;
    padding-bottom: 10px;
    border-radius: 10px;
    max-width: 850px;
}
.padding{
    height: 100px;
}

</style>